import React from 'react';
import { Row, Col, Button, Icon, Menu, Modal, message, Spin } from 'antd';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import HasLogged from './HasLogged';
import NotLogged from './NotLogged';
import HeaderMenuBar from './HeaderMenuBar';
import UserSign from '@/UserSign';
import { ApiPort } from '$ACTIONS/TLCAPI';
import { Cookie, formatAmount } from '$ACTIONS/util';
import { getMemberInfo } from '$DATA/userinfo';
import { get, post } from '$ACTIONS/TlcRequest';
import { LEARN_TIME } from '$ACTIONS/constantsData';
import HostConfig from '$ACTIONS/Host.config';
import CMSOBJ from '$DATA/stage.live.static.id';
import { getQueryVariable, getAffiliateReferralCode, Cookie as Cookiehelper } from '$ACTIONS/helper';
import OpenGame from '@/Games/openGame';
import { getUrlVars } from '$ACTIONS/util';
import ImageWithFallback from '@/ImageWithFallback/imgLocal';
import { connect } from "react-redux";
import { setSmarticoLoginContinue } from "../../store/thunk/gameThunk";
// 邮箱链接重置密码
// const DynamicResetPassword = dynamic(import('@/ResetPassword'), {
// 	loading: () => (""),
// 	ssr: true
// });

function HeaderComponent(props) {
	switch (props.status) {
		case 0:
			return (
				<NotLogged smallHeader={props.smallHeader} AlreadLogin={() => props.this.AlreadLogin()} {...props} />
			);
		case 1:
			return <HasLogged LoginExit={() => props.this.LoginExit()} {...props} key={JSON.stringify(props.status)} />;
		default:
			return null;
	}
}
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			livechatLink: 'javascript:;',
			smallHeader: '',
			allBalance: 0, // 子组件余额记录
			isLogin: 0, // 0 未登录 1 已登录
			showType: -1, // 子组件HasLogged：-1 初始化 0.1 隐藏个人中心 0.2 隐藏余额 1 个人中心简介 2 余额
			messageHasUnRead: false, // 是否有未读消息（交易/个人）
			csvisible: false,
			csurl: '',
			lockHeader: 0, // 锁定Header状态（0：free、大：2、小：1）
			toggleCenterPopover: false, // 是否显示个人中心弹出框
			logoutType: '', // 8号商城登出问题
			referFriendLearn: false, // 推荐好友导引步骤
			rafStep: 1, // 推荐好友导引步骤
			isDeposit: false,
			isVerified: false,
			verifyEmail: false, // email link彈窗
			verifyEmailResult: '', // eamil link驗證結果
			EmailResultMessage: '', // eamil link驗證結果
			loading: false,
			logoHref: ''
		};

		this.onUserSign = () => {}; // 初始化登录注册呼出事件
		this.showSimple = () => {}; // 初始化显示个人或者余额下拉
		this.hideSimple = () => {}; // 初始化隐藏个人或者余额下拉
		this.getShopURL = () => {}; // 初始化获取8号商城链接函数
		this.parentShowSimple = this.parentShowSimple.bind(this); // 父组件呼出个人余额下拉
		this.parentHideSimple = this.parentHideSimple.bind(this); // 父组件隐藏个人余额下拉
		this.onHasLoggedEvent = this.onHasLoggedEvent.bind(this); // 获取子组件切换个人余额下拉
		this.ReferrerLinkActivity = this.ReferrerLinkActivity.bind(this);
		this.ReferreeTaskStatus = this.ReferreeTaskStatus.bind(this);
		this.getBrowser = this.getBrowser.bind(this);
		// this.closeLearn = this.closeLearn.bind(this);
		this.referLearnCancel = this.referLearnCancel.bind(this);
		// this.toggleHeaderCenterLearn = this.toggleHeaderCenterLearn.bind(this); // 切换个人中心弹出框教程状态
		this.resizeThrottle = this.resizeThrottle.bind(this);
		this.toggleHeader = this.toggleHeader.bind(this);
		this.receiveMessage = this.receiveMessage.bind(this);

		this.timer = null;
		this.TLCLive = null;
		this.isClickTLCLive = false;
		this.urlParams = {}; // 传递一下链接参数
		this.verifyEmailLink = this.verifyEmailLink.bind(this); // 驗證email link
		global.PopUpLiveChat = this.PopUpLiveChat.bind(this); // 全局化打开客服窗口
		global.goReferFriend = this.goReferFriend.bind(this); // 全局化前往推荐好友
		global.showResultModal = this.showResultModal.bind(this); // 全局化半黑透明提示窗口
		global.showColorResultModal = this.showColorResultModal.bind(this); // 全局化半黑透明提示窗口
		global.GetThroughoutVerification = this.GetThroughoutVerification.bind(this);
	}
	componentDidMount() {
		const isSupportWebp = global.isSupportWebp ? '&displaying_webp' : '';
		// 因为导航二级菜单使用了全局属性url，所以每个有Hader的界面都需要赋值
		const affcode = getAffiliateReferralCode(),
			disabled =
				Cookiehelper.GetCookieKeyValue('CO_affiliate') != 'undefined' &&
				Cookiehelper.GetCookieKeyValue('CO_affiliate') != '';
		global.downloadLinks = `${window.location.origin}/cn/Appinstall.html${disabled ? '?affCode=' + affcode : ''}`;

		window.addEventListener('scroll', this.resizeThrottle);
		// 传递锁定Header状态的方法出去
		this.props.setLockHeader &&
			this.props.setLockHeader((statusId) => {
				this.setState({
					lockHeader: statusId,
					smallHeader: statusId === 1 ? 'zoom-out' : ''
				});
			});

		// 设置右侧滚动条宽度（避免弹出框因为滚动条抖动的问题）
		this.addStyle(
			`.ant-scrolling-effect .header-warp, .ant-scrolling-effect .fixed-right{right:${this.getScrollbarWidth()}px}.fixed-right{right: 0}`
		);

		// 设置登陆状态
		if (localStorage.getItem('access_token')) {
			this.setState({ isLogin: 1 });
			this.props.setLoginStatus && this.props.setLoginStatus(true);
		}

		// 默认获取客服链接，只有在客服链接不同的情况才会替换已记录客服链接
		// [ticket2201] initializing `serverUrl` value in background so that we SHOULD NOT display popup
		get(ApiPort.GETLiveChat, {viaInitialPageLoaded: true}).then((res) => {
			if (res && res.IS_FEAT_MAINTENANCE) {
				return;
			}
			if (res && res.url !== localStorage.getItem('serverUrl')) {
				localStorage.setItem('serverUrl', res.result);
				this.isClickTLCLive && this.showCS(res.result);
				this.isClickTLCLive = false;
			}
		});

		// 邮箱链接进入界面重置密码流程
		const enc = getQueryVariable('enc'),
			actionParam = getQueryVariable('action');
		// 如果action参数为registered则弹出注册窗口
		actionParam === 'registered' && global.goUserSign('2');
		if (enc) {
			// this.urlParams = {
			// 	enc: enc,
			// 	action: actionParam
			// };
			// this.setState({ showEmailResetPassword: true });
			window.location.href = `/cn/resetpassword?enc=${enc}`;
		}
		// email link的驗證
		if (
			global.location.pathname.indexOf('/cn/emailverification') != -1 &&
			enc &&
			actionParam === 'EmailVerification'
		) {
			// Router.push('/cn/');
			setTimeout(() => this.verifyEmailLink(enc), 500);
		}

		// 跳转充值窗口，可以指定支付方式
		const payMethod = getQueryVariable('payMethod');
		if (payMethod) {
			if (!localStorage.getItem('access_token')) {
				global.goUserSign('1');
				return;
			}
			setTimeout(() => {
				const walletKey =
					payMethod === 'default'
						? `wallet:{"type": "deposit"}`
						: `wallet:{"type": "deposit", "currentPayValue": "${payMethod}"}`;
				global.showDialog({ key: walletKey });
			});
		}

		// 推荐好友
		const raf = getQueryVariable('raf');
		if (!!raf) {
			localStorage.setItem('queleaReferrerId', raf);
			!localStorage.getItem('UserName') && this.setState({ referFriendLearn: true });
			if (!sessionStorage.getItem('isReferrerFirst')) {
				// this.ReferrerLinkActivity(raf);
				sessionStorage.setItem('isReferrerFirst', 'has');
			}
		}

		// 獲取是否支持第三方cookie
		window.addEventListener('message', this.receiveMessage, false);
		this.CheckUrkTokenToLogin();
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.resizeThrottle);
	}

	/**
     * @description: QRP登录
     * @return {*}
     */

	CheckUrkTokenToLogin() {
		let vars = getUrlVars();
		if (vars.ref && vars.ref !== '' && vars.token && vars.token !== '') {
			localStorage.setItem('access_token', JSON.stringify('Bearer' + ' ' + vars.token));
			localStorage.setItem('refresh_token', JSON.stringify(vars.ref));
		} else {
			return;
		}

		setTimeout(() => {
			this.AlreadLogin();
		}, 1000);

		setTimeout(() => {
			this.RefreshToken(vars.ref);
		}, 10000);
	}

	/**
     * @description: 刷新令牌
     * @param {*}
     * @return {*}
     */
	RefreshToken = (rstoken) => {
		const postData = {
			grantType: 'refresh_token',
			clientId: 'Fun88.CN.App',
			clientSecret: 'FUNmuittenCN',
			refreshToken: rstoken
		};

		post(ApiPort.RefreshTokenapi, postData)
			.then((res) => {
				if (res.isSuccess) {
					if (res.result.accessToken && res.result.refreshToken) {
						localStorage.setItem('access_token', JSON.stringify('bearer ' + res.result.accessToken));
						ApiPort.Token = 'bearer ' + res.result.accessToken;
						localStorage.setItem('refresh_token', JSON.stringify(res.result.refreshToken));
					} else {
						message.error('请重新登录，访问过期！', 3);
						setTimeout(() => {
							global.globalExit();
							Router.push('/cn/');
						}, 1500);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	receiveMessage(evt) {
		if (evt.data === 'MM:3PCunsupported') {
			localStorage.setItem('thirdPartyCookie', false);
			// console.log('第三方cookie不支持');
		} else if (evt.data === 'MM:3PCsupported') {
			localStorage.setItem('thirdPartyCookie', true);
			// console.log('支持第三cookie');
		}
	}

	PopUpLiveChat(useCache=false) {
		// this.setState({
		// 	csvisible: true
		// });
		if (useCache) {
			const serverUrl = localStorage.getItem('serverUrl');
			if (serverUrl) {
				this.showCS(serverUrl);
			}
		}

		// [ticket2201] re-checking `serverUrl` value in user's clicks so that we SHOULD display popup modal
		get(ApiPort.GETLiveChat).then((res) => {
			if (res && res.IS_FEAT_MAINTENANCE) {
				return;
			}

			if (res && res.result) {
			localStorage.setItem('serverUrl', res.result);
			const serverUrl = localStorage.getItem('serverUrl');
			if (serverUrl) {
				this.showCS(serverUrl);
			} else {
				// 记录点击状态，如果当前无客服链接返回，点击后记录，异步响应后打开客服窗口
				this.isClickTLCLive = true;
			}
			}

		});

	}
	ReferreeTaskStatus() {
		// if (!sessionStorage.getItem("isLoginStatusFirst")) {

		// getMemberInfo((res) => {
		// 	this.setState({
		// 		isDeposit: !!res.isDeposited,
		// 		isVerified: res.isVerifiedPhone && res.isVerifiedPhone[1] && res.isVerifiedEmail[1]
		// 	});
		// });
		let memberInfo = JSON.parse(localStorage.getItem('memberInfo'));
		if (memberInfo) {
			this.setState({
				isDeposit: !!memberInfo.isDeposited,
				isVerified: memberInfo.isVerifiedPhone && memberInfo.isVerifiedPhone[1] && memberInfo.isVerifiedEmail[1]
			});
		}
		get(ApiPort.ReferreeTaskStatus).then((data) => {
			if (localStorage.getItem('OpenReferDone')) {
				return;
			}
			if (data && data.isSuccess && data.result) {
				console.log('推荐好友状态', data);
				if (memberInfo.displayReferee) {
					if (!data.result.isDeposited) {
						this.setState({
							rafStep: 2,
							referFriendLearn: true
						});
					} else {
						if (!data.result.isContactVerified) {
							this.setState({
								rafStep: 3,
								referFriendLearn: true
							});
						} else {
							this.setState({
								rafStep: 4,
								referFriendLearn: true
							});
						}
					}
				} else {
					if (data.result.isActiveCampaign && data.result.isContactVerified) {
						this.GetThroughoutVerification();
					}
				}
			}
		});
	}

	//获取推荐好友的状态
	GetThroughoutVerification() {
		post(ApiPort.ThroughoutVerification)
			.then((data) => {
				let GameReferModal = localStorage.getItem(localStorage.getItem('UserName') + '_GameReferModal');
				if (GameReferModal) {
					return;
				}
				if (data.isSuccess && data.result) {
					this.setState({
						GameOpenModal: true
					});
				} else {
					//重置修改密码后，才弹下面的窗口，否则不弹
					if (data.errors && data.errors[0].errorCode == 'VAL99903') {
						return;
					}
					//弹出不符合资格弹窗
					this.setState({
						Referineligible: true
					});
				}
				this.setState({
					referFriendLearn: false
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	goReferFriend() {
		// if (!localStorage.getItem('access_token')) {
		// 	global.goUserSign('1');
		// 	return;
		// }
		Router.push('/cn/Refer');
		return;
		const hide = message.loading('加载中，请稍后...');
		get(ApiPort.GetQueleaActiveCampaign).then((data) => {
			hide();
			Router.push('/cn/Refer');
			// if (data && data.isSuccess) {
			// 	Router.push('/cn/Refer');
			// } else {
			// 	Modal.info({
			// 		title: '推荐好友活动尚未开始',
			// 		centered: true,
			// 		okText: '确认',
			// 		content: '敬请期待乐天堂推荐好友获取彩金活动'
			// 	});
			// }
		});

		// Pushgtagdata('RAF_mainnav');
	}
	getBrowser = () => {
		var browser = {
				msie: false,
				firefox: false,
				opera: false,
				safari: false,
				chrome: false,
				netscape: false,
				appname: 'unknown',
				version: 0
			},
			ua = window.navigator.userAgent.toLowerCase();
		if (/(msie|firefox|opera|chrome|netscape)\D+(\d[\d.]*)/.test(ua)) {
			browser[RegExp.$1] = true;
			browser.appname = RegExp.$1;
			browser.version = RegExp.$2;
		} else if (/version\D+(\d[\d.]*).*safari/.test(ua)) {
			// safari
			browser.safari = true;
			browser.appname = 'safari';
			browser.version = RegExp.$2;
		}
		return {
			BrowserType: browser.appname + browser.version,
			BrowserName: browser.appname,
			BrowserVersion: browser.version
		};
	};
	ReferrerLinkActivity(referCode) {
		let data = {
			ReferrerId: referCode,
			LandingPage: window.location.origin,
			BrowserType: this.getBrowser().BrowserType,
			BrowserName: this.getBrowser().BrowserName,
			BrowserVersion: this.getBrowser().BrowserVersion,
			BrowserPlatform: window.navigator.platform,
			UserAgent: window.navigator.userAgent,
			HttpReferer: window.location.origin + '/?raf=' + referCode
		};
		post(ApiPort.ReferrerLinkActivity, data).then((res) => {}).catch((error) => {});
	}
	referLearnCancel() {
		this.setState({ referFriendLearn: false });
		localStorage.setItem('OpenReferDone', localStorage.getItem('UserName'));
		this.SetdisplayRefereeStatus();
	}
	goUserCenter(key) {
		if (~global.location.pathname.indexOf('/cn/usercenter')) {
			if (key === 'vip') {
				Router.push('/cn/vip');
			} else {
				global.goPage(key, true);
			}
		} else {
			if (key === 'vip') {
				Router.push('/cn/vip');
			} else {
				sessionStorage.setItem('userCenterPageKey', key);
				Router.push('/cn/usercenter');
			}
		}
	}
	// 切换个人中心弹出框教程状态
	// toggleHeaderCenterLearn(status) {
	//     this.setState({
	//         // smallHeader: "", 若沒有comment out this line, 會造成onMouseEnter進個人或者餘額時, header top消失
	//         //lockHeader: status ? 2 : 0,
	//         //toggleCenterPopover: status
	//     });
	// }
	// closeLearn() {
	//     //this.toggleHeaderCenterLearn(false);
	//     let LearnArr = Cookie("learnStep").split("");
	//     LearnArr.splice(2, 1, "1");
	//     Cookie("learnStep", LearnArr.join(""), { expires: LEARN_TIME });
	//     Pushgtagdata("Profilepage_userguide");
	// }
	// 已登录组件事件传递
	onHasLoggedEvent(showEvent, hideEvent) {
		this.showSimple = showEvent;
		this.hideSimple = hideEvent;
	}
	parentShowSimple(type) {
		this.setState({ showType: type });
		this.showSimple(type, 'top');
	}
	parentHideSimple(type) {
		this.setState({ showType: 0 });
		this.hideSimple(type);
	}
	resizeThrottle() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(this.toggleHeader, 30);
	}
	toggleHeader() {
		if (this.state.lockHeader) {
			return;
		}
		const h = window,
			e = document.body,
			a = document.documentElement;
		const offsetY = Math.max(0, h.pageYOffset || a.scrollTop || e.scrollTop || 0) - (a.clientTop || 0);
		this.setState({ smallHeader: offsetY >= 10 ? 'zoom-out' : '' });
	}
	getScrollbarWidth() {
		// Creating invisible container
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll'; // forcing scrollbar to appear
		outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
		document.body.appendChild(outer);

		// Creating inner element and placing it in the container
		const inner = document.createElement('div');
		outer.appendChild(inner);

		// Calculating difference between container's full width and the child width
		const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

		// Removing temporary elements from the DOM
		outer.parentNode.removeChild(outer);

		return scrollbarWidth;
	}
	addStyle(newStyle) {
		const overflowDistance = 'overflow_distance';
		var styleElement = document.getElementById(overflowDistance);

		if (!styleElement) {
			styleElement = document.createElement('style');
			styleElement.type = 'text/css';
			styleElement.id = overflowDistance;
			document.getElementsByTagName('head')[0].appendChild(styleElement);
			styleElement.appendChild(document.createTextNode(newStyle));
		}
	}

	showResultModal(value, type = true, zIndex = 1501, key, name = '') {
		// 半透黑彈窗
		const opacityMaskDialog = Modal.info({
			title: ``,
			icon: <div />,
			zIndex: zIndex,
			centered: true,
			mask: true,
			footer: null,
			content: (
				<div>
					<img src={`/cn/img/icons/${type ? 'icon-success' : 'icon-error'}.png`} width="35px" />
					<p
						style={{
							marginTop: 10,
							marginBottom: 0,
							padding: key == 'otp' ? '0' : '0 14px'
						}}
					>
						{value}
					</p>
				</div>
			),
			className: `showInfoModal opacity ${name}`
		});
		setTimeout(() => {
			opacityMaskDialog.destroy();
		}, 3000);
	}

	showColorResultModal(value, type, zIndex = 1501, key, name = '') {
		// 半透黑彈窗
		const opacityMaskDialog = Modal.info({
			title: ``,
			zIndex: zIndex,
			centered: true,
			mask: false,
			content: (
				<div>
					<img
						width="20"
						style={{ marginRight: '0.3rem' }}
						src={`/cn/img/icons/${type ? 'icon-checked' : 'failed-filled'}.png`}
					/>
					<div
						style={{
							marginTop: '0.2rem',
							color: type ? '#0CCC3C' : '#cc0c0c'
						}}
					>
						{value}
					</div>
				</div>
			),
			className: `showInfoColorModal ${type} ${name}`
		});
		setTimeout(() => {
			opacityMaskDialog.destroy();
		}, 3000);
	}

	AlreadLogin = () => {
		this.setState({ isLogin: 1, logoutType: '' });
		this.props.setLoginStatus && this.props.setLoginStatus(true);
	};

	LoginExit = () => {
		this.setState({ isLogin: 0 });
		this.props.setLoginStatus && this.props.setLoginStatus(false);
	};

	showCS(url) {
		// let _this = this;
		// if (this.state.csurl != '') {
		if (!this.TLCLive || (this.TLCLive && this.TLCLive.closed)) {
			this.TLCLive = window.open(
				'about:blank',
				'chat',
				'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=540, height=650'
			);
			this.TLCLive.document.title = '乐天使在线客服';
			this.TLCLive.location.href = url;
			this.TLCLive.focus();
		} else {
			this.TLCLive.focus();
		}
		// _this.setState({
		// 	csvisible: false
		// });
		// } else {
		// 	message.error('哎呀！出现错误请稍后重试！');
		// }
	}

	cschange() {
		this.setState({
			csvisible: false
		});
	}

	verifyEmailLink(enc) {
		this.setState({ loading: true });
		let data = {
			EncyptedText: enc
		};
		this.setState({ verifyEmail: true });
		post(ApiPort.POSTEmailVerifyLink, data)
			.then((res) => {
				if (res != '') {
					res.result &&
						this.setState({
							loading: false,
							verifyEmailResult: res.isSuccess,
							EmailResultMessage: res.result.message
						});
				}
			})
			.catch((error) => {});
	}

	/**
     * @description: 写入打开Referee弹窗的状态
     * @return {*}
     */

	SetdisplayRefereeStatus() {
		if (this.state.rafStep == 4) {
			localStorage.setItem(localStorage.getItem('UserName') + '_displayReferee', true);
		}
	}

	SetGameReferStatus() {
		localStorage.setItem(localStorage.getItem('UserName') + '_GameReferModal', 'TRUE');
	}

	displayLiveChatUI() {
		let { livechatLink } = this.state;
		/* 在线客服按钮 */
		return <Col span={1} className="csBtn">
					<a href={livechatLink} target="_blank" style={{position:'absolute',left:'-9999em'}} id="livechat_opener">&nbsp;</a>
					<div
						className="live-server-btn"
						onClick={() => {
							Modal.info({
									icon: "",
									title: '重要提示',
									okText: '好的',
									className: 'commonModal',
									content: <div>页面将会开启另一个客服窗口</div>,
									onOk: () => {
										// realtime GETLiveChat, because there are two difference links when beforeLogin/afterLogin
										get(ApiPort.GETLiveChat, {viaInitialPageLoaded: false}).then(res=>{
											if (res.isSuccess) {
												localStorage.setItem("serverUrl", res.result);
												this.setState({livechatLink: res.result, livechatVisible: true}, ()=>{
													document.getElementById('livechat_opener').click();
													Pushgtagdata('CS', 'Launch', 'Livechat_TopNav');
												});
											}
										})
										}
							});
						}}
					>
						<img className='help-center' src="/cn/img/home/icon/icon-help-center.png" />
					</div>
				</Col>
	}

	displayTopToolBarUI() {
		return this.state.isLogin ? (
			<Menu
				mode="horizontal"
				className="right-head zoom-in-section head-menu-right"
				onClick={(item) => {
					switch (item.key) {
						case '3':
							global.showDialog({
								key: 'wallet:{"type": "deposit"}'
							});
							Pushgtagdata('Deposit Nav', 'Click', 'Deposit_TopNav');
							break;
						case '4':
							global.showDialog({
								key: 'wallet:{"type": "withdraw"}'
							});
							Pushgtagdata(
								'Withdrawal Nav',
								'Click',
								'Withdrawal_TopNav'
							);
							break;
						case '5':
							global.showDialog({
								key: 'wallet:{"type": "transfer"}'
							});
							Pushgtagdata(
								'Transfer Nav',
								'Click',
								'Transfer_TopNav'
							);
							break;
						default:
							break;
					}
				}}
			>
				<Menu.Item key="1" className="clear-padding">
					<div
						className={`user-center-btn${this.state.showType === 1
							? ' active'
							: ''}`}
						onMouseEnter={() => this.parentShowSimple(1)}
						onMouseLeave={() => this.parentHideSimple(0.1)}
					>
						<span>个人中心</span>
						<i
							className={`tlc-sprite user-message ${this.state
								.messageHasUnRead
								? 'remind-circle'
								: ''}`}
						/>
						<Icon type="caret-down" />
					</div>
				</Menu.Item>
				<Menu.Item key="2" className="clear-padding">
					<div
						className={`wallet-center-btn${this.state.showType === 2
							? ' active'
							: ''}`}
						onMouseEnter={() => this.parentShowSimple(2)}
						onMouseLeave={() => this.parentHideSimple(0.2)}
					>
						<span>￥</span>
						<span>{formatAmount(this.state.allBalance)}</span>
						<Icon type="caret-down" />
					</div>
				</Menu.Item>
				<Menu.Item key="3" className="header-btn btnYellow">
					存款
				</Menu.Item>
				<Menu.Item key="5" className="header-btn btnBlue">
					转账
				</Menu.Item>
				<Menu.Item key="4" className="header-btn btnPurple">
					提款
				</Menu.Item>
			</Menu>
		) : (
			<Menu
				mode="horizontal"
				className="zoom-in-section head-menu-right text-right"
				onClick={(item) => {
					switch (item.key) {
						case '1':
						case '2':
							this.onUserSign(item.key);
							break;
						default:
							break;
					}
				}}
			>
				<Menu.Item key="1" className="header-btn btn-orange login-btn">
					登录
				</Menu.Item>
				<Menu.Item
					key="2"
					className="header-btn btn-blue btn-header-register register-btn"
				>
					注册
				</Menu.Item>
			</Menu>
		)
	}

	render() {
		const { smallHeader, showType, Referineligible, GameOpenModal } = this.state;
		const { definedHeaderNode } = this.props;
		return (
			<React.Fragment>
				{/* 自定义Header并不损失Header处引入的功能 */}
				{!definedHeaderNode ? null : definedHeaderNode}
				<div
					style={{
						display: !definedHeaderNode ? 'block' : 'none',
						color: '#fff'
					}}
				>
					{/* {this.state.toggleCenterPopover ? (
						<div className="tlc-learn-shadow-wrap">
							<div className="common-distance">
								<img src="/cn/img/learn/user-center-popover_1.png" />
							</div>
							<div className="tlc-learn-shadow header-center" onClick={this.closeLearn}>
								<button className="learn-knew" />
							</div>
						</div>
					) : null} */}

					<div className={`header-warp next-header-bar-wrap common-distance-wrap ${smallHeader}`}>
						<div className="common-distance">
							<div className='navbar-row-1'>
								<div className='navbar-row-1-left'>
									<div
										className="logo-wrap"
										onClick={() => {
											Router.push('/cn');
										}}
									>
										<ImageWithFallback
											// src="/cn/img/home/logo@2x.png"
											// width={100}
											// height={46}
											src="/cn/img/euro2024/euro2024-logo.png"
											width={137}
											height={41}
											alt="F88"
										/>
									</div>
								</div>
								<div className='navbar-row-1-right'>
									<Menu
										mode="horizontal"
										// zoom-out-section
										className="helpMenu left-head head-menu-left"
										onClick={(item) => {
											switch (item.key) {
                                                case '2':
                                                    Pushgtagdata(
                                                        'Home_TopNav',
                                                        'Go_to_Mission',
                                                        'Home_TopNav_C_Mission'
                                                    );
                                                    if (localStorage.getItem("access_token") == null) {
														this.props.setSmarticoContinue(true)
                                                        global.goUserSign("1");
                                                        return
                                                    }
													Router.push('/cn/rewards-centre');
                                                    // Pushgtagdata('Support_homepage');
                                                    break;
												case '3':
													Router.push('/cn/Help');
													Pushgtagdata('Support_homepage');
													break;
												default:
													break;
											}
										}}
									>
										<Menu.Item key="1">
											<div className="next-menu-bar">{this.displayTopToolBarUI()}</div>
										</Menu.Item>
                                        {this.props.smarticoVisible && (
											<Menu.Item key="2" className="rewardsCentre">
												<div className="rewardsCentreNormal">
													<img src="/cn/img/home/header/Rewards_Centre_Normal.webp" alt="" width={"100%"} />
												</div>
												<div className="rewardsCentreHover">
													<img src="/cn/img/home/header/Rewards_Centre_Hover.webp" alt="" width={"100%"} />
												</div>
                                            </Menu.Item>
                                        )}
										<Menu.Item key="3" className="helpCenter">
											<a>帮助中心</a>
										</Menu.Item>
										<Menu.Item key="9" className="liveChat">
											{this.displayLiveChatUI()}
										</Menu.Item>
									</Menu>
								</div>
							</div>
							<Row>
								<Col span={24}>
									<HeaderMenuBar
										getShopURL={(run) => {
											this.getShopURL = run;
										}}
										setShopURL={this.props.setShopURL}
										setCasinoGameList={this.props.setCasinoGameList}
										setSlotGameList={this.props.setSlotGameList}
										setP2PGameList={this.props.setP2PGameList}
										getPromotionList={this.props.getPromotionList}
									/>
								</Col>
							</Row>
						</div>
					</div>

					<HeaderComponent
						ReferreeTaskStatus={this.ReferreeTaskStatus}
						goUserCenter={this.goUserCenter}
						onChangeShowType={(v) => {
							this.setState({ showType: v });
						}}
						onHasLoggedEvent={this.onHasLoggedEvent}
						setUserAllBalance={(v) => {
							this.setState({ allBalance: v });
						}}
						toggleCenterPopover={this.state.toggleCenterPopover}
						// toggleHeaderCenterLearn={this.toggleHeaderCenterLearn}
						setUsercnnterCircle={this.props.setUsercnnterCircle}
						headerHeightLock={this.props.headerHeightLock}
						getShopURL={() => {
							this.getShopURL();
						}}
						setMessageHasUnRead={(v) => {
							this.setState({ messageHasUnRead: v });
						}}
						setCircleHasUnRead={this.props.setCircleHasUnRead}
						setUserCenterMoney={this.props.setUserCenterMoney}
						setUserCenterMemberInfo={this.props.setUserCenterMemberInfo}
						smallHeader={this.state.smallHeader}
						status={this.state.isLogin}
						exitShop={(v) => {
							this.setState({ logoutType: v });
						}}
						this={this}
						key={this.state.isLogin + 'status'}
						GetThroughoutVerification={() => {
							this.GetThroughoutVerification();
						}}
					/>
				</div>

				<UserSign
					getShopURL={() => {
						this.getShopURL();
					}}
					AlreadLogin={() => this.AlreadLogin()}
					onUserSign={(handleEvent) => (this.onUserSign = handleEvent)}
				/>

				<Modal
					title="在线客服"
					closeIcon={<Icon type="close" style={{ fontSize: '18px' }} />}
					className="modal-pubilc"
					visible={this.state.csvisible}
					onOk={() => this.cschange()}
					onCancel={() => this.cschange()}
					width={450}
					footer={null}
					zIndex={9999}
				>
					<p> 您需要帮助吗？请选择想要的在线客服服务帮您答疑解惑～</p>
					<div className="csbox">
						<div
							className="l"
							onClick={() => {
								this.showCS();
							}}
						>
							<img src="/cn/img/cs/live.png" />
							<p>在线客服专员</p>
						</div>
						<div
							className="r"
							onClick={() => {
								this.showCS();
							}}
						>
							<img src="/cn/img/cs/ai.png" />
							<p>在线客服专员</p>
						</div>
					</div>
				</Modal>
				<Modal
					visible={this.state.referFriendLearn}
					footer={false}
					width={316}
					maskClosable={false}
					onCancel={() => {
						this.referLearnCancel();
					}}
				>
					<h3 className="refer-friend-title center">新手注册专享 等你领！</h3>
					<ul className="invite-list-step has-login learn">
						<li>
							<div className="invite-step-content">
								<h4>第一步</h4>
								<p>点击 “立即注册” 成为我们的会员</p>
								<Button
									type="danger"
									onClick={() => {
										this.referLearnCancel();
										global.goUserSign('2');
									}}
									disabled={this.state.rafStep !== 1}
								>
									立即注册
								</Button>
							</div>
							{this.state.rafStep !== 1 ? (
								<Icon type="check-circle" theme="filled" className="success" />
							) : (
								<Icon type="check-circle" />
							)}
						</li>
						<li>
							<div className="invite-step-content">
								<h4>第二步</h4>
								<p>进入存款页面完成首存</p>
								<Button
									type="danger"
									onClick={() => {
										this.referLearnCancel();
										global.showDialog({
											key: 'wallet:{"type": "deposit"}'
										});
										this.SetdisplayRefereeStatus();
									}}
									disabled={this.state.isDeposit || this.state.rafStep === 1}
								>
									立即充值
								</Button>
							</div>
							{this.state.isDeposit && this.state.rafStep !== 1 ? (
								<Icon type="check-circle" theme="filled" className="success" />
							) : (
								<Icon type="check-circle" />
							)}
						</li>
						<li>
							<div className="invite-step-content">
								<h4>第三步</h4>
								<p>完成存款后进行邮箱和手机的验证</p>
								<Button
									type="danger"
									onClick={() => {
										this.referLearnCancel();
										this.goUserCenter('userinfo');
										this.SetdisplayRefereeStatus();
									}}
									disabled={this.state.isVerified || this.state.rafStep === 1}
								>
									立即验证
								</Button>
							</div>
							{this.state.isVerified && this.state.rafStep !== 1 ? (
								<Icon type="check-circle" theme="filled" className="success" />
							) : (
								<Icon type="check-circle" />
							)}
						</li>
					</ul>
					<Button block type="link" size="small" onClick={this.referLearnCancel}>
						关闭
					</Button>
				</Modal>

				<Modal
					title={'温馨提示'}
					className="SecurityAnnouncement modal-pubilc OTP-modal"
					visible={Referineligible}
					closable={true}
					onCancel={() => {
						this.setState({
							Referineligible: false
						});
						this.SetGameReferStatus();
					}}
					centered={true}
					width={400}
					footer={null}
					zIndex={1500}
				>
					<Row>
						<Col span={24}>
							<img className="otp-warn" src="/cn/img/icons/icon-warn.svg" alt="icon-warn" />
							<h3 className="otp-modal-title">不符合资格</h3>
							<p>抱歉，目前您的账户不符合推荐好友活动的资格。请尝试申请其他优惠，或联系在线客服咨询。</p>
						</Col>
					</Row>
					<div className={`btn-wrap otp-btn-wrap`}>
						<center>
							<Button
								className="changeVerify"
								onClick={() => {
									this.setState({
										Referineligible: false
									});
									this.SetGameReferStatus();
								}}
							>
								关闭
							</Button>
							<Button className="otpBtn readMore" onClick={() => global.PopUpLiveChat()} block>
								联系在线客服
							</Button>
						</center>
					</div>
				</Modal>

				<Modal
					title={'温馨提示'}
					visible={GameOpenModal}
					className="modal-pubilc ReferModal"
					maskClosable={false}
					footer={null}
					onCancel={() => {
						this.setState({
							GameOpenModal: false
						});
						this.SetGameReferStatus();
					}}
				>
					<React.Fragment>
						<center>
							<img className="referGame" src="/cn/img/icons/icon-reject.svg" />
							<h3 style={{ paddingTop: '10px' }}>
								<b>恭喜您 ！</b>
							</h3>
						</center>
						<div className="note" style={{ textAlign: 'left' }}>
							完成推荐好友活动条件，彩金将在24小时内到帐。
						</div>
						<h4 className="RecommendedGames">推荐游戏</h4>

						<div className="GameReferImg">
							{/* <OpenGame
                                itemsData={{
                                    type: "Game",
                                    name: "乐体育",
                                    code: "SB2",
                                    imageUrl: "/cn/img/game/sports.jpg",
                                    isMaintenance: true,
                                    isTournament: false,
                                    isHot: false,
                                    isNew: false,
                                }}
                                hideTag={true}
                            /> */}
							<OpenGame
								itemsData={{
									type: 'Game',
									name: '热门游戏',
									code: 'AVIATOR',
									imageUrl: '/cn/img/game/v2g.jpg',
									isMaintenance: false,
									isTournament: false,
									isHot: true,
									isNew: false
								}}
								hideTag={true}
							/>
							<OpenGame
								itemsData={{
									type: 'Category',
									name: '至尊堂（AG）',
									code: 'AG',
									imageUrl: '/cn/img/game/ag.jpg',
									isMaintenance: false,
									isTournament: false,
									isHot: false,
									isNew: false,
									gameCatCode: 'LiveCasino',
									gameCatId: 124
								}}
								hideTag={true}
							/>
						</div>
					</React.Fragment>
				</Modal>

				{/* email link 驗證彈窗*/}
				<Modal
					title={!this.state.loading ? this.state.verifyEmailResult ? '成功验证邮箱' : '验证邮箱失败' : ''}
					closeIcon={<Icon type="close" style={{ fontSize: '18px' }} />}
					className="modal-pubilc"
					// visible={true}
					visible={this.state.verifyEmail}
					onOk={this.handleEvent}
					onCancel={() => this.setState({ verifyEmail: false })}
					width={400}
					footer={null}
				>
					<Spin spinning={this.state.loading}>
						{this.verifyEmailResult != '' && (
							<React.Fragment>
								<div
									style={{
										padding: '30px 40px 47px 40px',
										margin: '0 auto'
									}}
									dangerouslySetInnerHTML={{
										__html: this.state.EmailResultMessage
									}}
								/>
								<Button
									size="large"
									type="primary"
									block
									onClick={() => {
										this.setState({ verifyEmail: false });
										if (this.state.verifyEmailResult) {
											global.goUserSign('1');
											Pushgtagdata('Submit_login');
										}
									}}
								>
									{this.state.verifyEmailResult ? '立即登录' : '确认'}
								</Button>
							</React.Fragment>
						)}
					</Spin>
				</Modal>
				{/* {this.state.showEmailResetPassword ? <DynamicResetPassword {...this.urlParams} /> : null} */}
				{this.state.isLogin ? (
					<iframe src="https://www.zbbc88.com/cms/3rdCookie/start.html" style={{ display: 'none' }} />
				) : null}
				{/* 8号商城登出Iframe */}
				{this.state.logoutType ? <iframe src={this.state.logoutType} style={{ display: 'none' }} /> : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = function (state) {
    return {
        smarticoVisible: state.game.smarticoVisible,
    };
};

const mapDispatchToProps = function (dispatch) {
	return {
		setSmarticoContinue: (isFromSmarticoLogin) => {
			dispatch(setSmarticoLoginContinue(isFromSmarticoLogin));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);