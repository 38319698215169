import React from "react";
import { Provider } from "react-redux";
import store from "$STORE/store";
import { Modal, Tabs, Icon, message } from "antd";
import Login from "./Login";
import Register from "./Register";
import ForgotPwd from "@/ForgotPwd/";
import { connect } from "react-redux";
import { setSmarticoLoginContinue } from "../../store/thunk/gameThunk";

message.config({
    top: 100,
    duration: 2,
    maxCount: 1,
});
const { TabPane } = Tabs;
class UserSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "1",
            visible: false,
            agreement: false,
        };
    }

    componentDidMount() {
        global.goUserSign = (key) => this.showmodal(key); // 全局化登陆注册弹窗事件
        this.props.onUserSign(this.showmodal); // 传出登陆注册弹窗事件
    }

    showmodal = (key) => {
        this.setState({
            visible: !!key,
            activeKey: key,
        });
    };

    hidemodal = () => {
        this.setState({
            visible: false
        })
    };

    showagreement() {
        this.setState({
            agreement: true,
        });
    }

    handleEvent = (e) => {
        this.setState({
            visible: false,
            agreement: false,
        });
    };

    RefLogin = (ref) => {
        this.Login = ref;
    };

    RefRegister = (ref) => {
        this.Register = ref;
    };

    render() {
        return (
            <div>
                <Modal
                    title="欢迎您"
                    closeIcon={
                        <Icon type="close" style={{ fontSize: "18px" }} />
                    }
                    className="modal-pubilc login-and-registration"
                    visible={this.state.visible}
                    onOk={this.handleEvent}
                    onCancel={()=>{
                        if (this.props.smarticoLoginContinue) {
                            this.props.setSmarticoContinue(false)
                        }
                        this.handleEvent()
                    }}
                    zIndex={2000}
                    width={400}
                    footer={null}
                    destroyOnClose={true}
                >
                    <Provider store={store}>
                        <Tabs
                            tabPosition="top"
                            className="tabs-modal-pubilc"
                            size="small"
                            activeKey={this.state.activeKey}
                            onChange={(activeKey) => {
                                this.setState({ activeKey });
                                Pushgtagdata(
                                    activeKey === "1"
                                        ? "Login_login"
                                        : "Register_register"
                                );
                            }}
                        >
                            <TabPane tab="登入" key={"1"}>
                                <Login
                                    RefLogin={(ref) => this.RefLogin(ref)}
                                    getShopURL={() => {
                                        this.props.getShopURL();
                                    }}
                                    AlreadLogin={() => this.props.AlreadLogin()}
                                    handleEvent={() => this.handleEvent()}
                                    showagreement={() => this.showagreement()}
                                    hidemodal={() => this.hidemodal()}
                                    openForgotPwd={() => {
                                        this.handleEvent();
                                        this.refs.Showmodal.showModal(1);
                                        global.Pushgtagpiwikurl && global.Pushgtagpiwikurl("forget_info");
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="注册" key={"2"}>
                                <Register
                                    RefRegister={(ref) => this.RefRegister(ref)}
                                    login={() => {
                                        this.Login.Registerdata(
                                            this.Register.state.UserName,
                                            this.Register.state.UserPwd
                                        );
                                        this.Login.Login();
                                    }}
                                    showmodal={(e) => this.showmodal(e)}
                                    hidemodal={() => this.hidemodal()}
                                    showagreement={() => this.showagreement()}
                                />
                            </TabPane>
                        </Tabs>
                    </Provider>
                </Modal>
                <Modal
                    title="服务协议"
                    closeIcon={
                        <Icon type="close" style={{ fontSize: "18px" }} />
                    }
                    className="modal-pubilc"
                    zIndex={2001}
                    visible={this.state.agreement}
                    onOk={() => {
                        this.setState({
                            agreement: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            agreement: false,
                        });
                    }}
                    width={600}
                    footer={null}
                    centered={true}
                >
                    <ul className="list-style">
                        <li>
                            任何串关注单、任何平局、滚球赛事、取消的赛事、提早兑现的注单或欧洲盘赔率低于
                            1.7
                            的投注将不符合条件申请优惠，所有活动必须遵守本站标准条款。{" "}
                        </li>
                        <li>
                            任何会员的注册姓名必须与提款银行的账户姓名一致。若违反，本站保留拒绝申请红利，并取消其已获红利及盈利的权利。{" "}
                        </li>
                        <li>
                            每位会员、每户、每一住址、每一电子邮箱地址、每一电话号码、相同支付方式、相同在线存款/信用卡/银行账户号码/money-booker及IP地址只可享受一次本优惠。{" "}
                        </li>
                        <li>
                            所有活动的对象仅为娱乐性玩家，有资格参与任何优惠活动的用户，须遵守本站制定的规则与决定。如发现会员为职业赌徒或只为寻求红利的玩家，本站保留取消红利的权利。{" "}
                        </li>
                        <li>
                            本站保留对本优惠的修订、终止和最终解释权，以及在无通知的情况下作出改变所有活动的权利；或者通过邮件方式通知会员、作出改变的权利。
                            如果本次活动由于一些不可预测或情势超出本站控制之外的技术错误，本站对此将不承担任何责任。{" "}
                        </li>
                        <li>
                            本站有权在第一时间取消一切利用对赌涉嫌欺诈行为会员的所有已获红利及盈利。
                            所有产品首存和再存红利完成后均需在90天内领取，否则将过期失效。
                            已派发奖励在有效期内未使用将自动扣除，所有免费彩金有效期为30天。{" "}
                        </li>
                    </ul>
                </Modal>
                <ForgotPwd ref="Showmodal" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    smarticoLoginContinue: state.game.smarticoLogin
});

const mapDispatchToProps = function (dispatch) {
    return {
        setSmarticoContinue: (isFromSmarticoLogin) => {
            dispatch(setSmarticoLoginContinue(isFromSmarticoLogin));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSign);
